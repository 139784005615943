<template>
  <div>
    <div class='text-2xl lg:text-3xl font-semibold uppercase w-full lg:w-1/4'>
      평점 확인
    </div>
    <div class='flex flex-col lg:flex-row gap-y-8 lg:gap-x-8 mt-4'>
      <div class='w-full lg:w-1/4 flex flex-col-reverse lg:flex-col gap-y-4'>
        <div class='w-full lg:w-40 h-28 lg:h-40 text-white flex flex-col justify-center items-start p-8'
          :style='`background-color: ${eventMainThemeColor};`'>
          <div class='text-lg uppercase font-medium '>총 이수 평점</div>
          <div class='text-5xl font-normal'>{{totalScore}}점</div>
        </div>
        <div>
          <h3 class='text-lg text-gray-800 font-medium lg:mt-8 mb-2'>평점 안내사항 (최대 15점)</h3>
          <p class='text-sm text-gray-700 font-normal pr-4'>
            본 평점은 현장 바코드 스캔 기록시간을 검토하여 합산한 평점입니다. 정정이 필요한 경우, 준비사무국(gbccreg@intercom.co.kr)으로 11일(목)까지 연락주시기 바랍니다.
          </p>
        </div>
      </div>
      <div class='w-full lg:w-3/4'>
        <table  v-if='!noWatchVideoData'
          class='table-fixed w-full text-sm lg:text-base'>
          <thead>
            <tr class='uppercase text-gray-600'>
              <th class='py-2 lg:py-4 px-2 text-left w-6 lg:w-8 whitespace-nowrap align-top'></th>
              <th class='py-2 lg:py-4 px-2 text-left w-32 lg:w-64 whitespace-nowrap align-top'>날짜</th>
              <th class='py-2 lg:py-4 px-2 text-right whitespace-nowrap align-top'>누적 이수 시간</th>
              <th class='py-2 lg:py-4 px-2 text-right'>
                이수 평점
              </th>
            </tr>
          </thead>
          <my-page-watched-videos 
            v-for='(dayData, index) in myDataByDay'
            :key='`data-for-day-${index}`'
            :data-watched-videos-for-day='dayData'
            :show-score='true' />
        </table>
        <div v-else
          class='my-2 py-16 bg-gray-100 rounded-md text-gray-600 text-center'>
          이수 평점이 없습니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyPageWatchedVideos     from '@/components/my-page/MyPageWatchedVideos.vue'
import isEmpty                 from 'lodash/isEmpty'
import ScoreCalculationHelpers from '@/utils/score-calculation-helpers'
import DateTimeStringHelpers   from '@/utils/date-time-string-helpers'

export default {
  name: 'MyPageScore',
  components: {
    MyPageWatchedVideos,
  },
  computed: {
    ...mapGetters('myPages', [
      'myDataByDay',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'eventMainThemeColor',
    ]),
    noWatchVideoData () {
      return isEmpty(this.myDataByDay)
    },
    totalScore () {
      return Object.keys(this.myDataByDay).map((key) => {
        let total = 0.0
        this.myDataByDay[key].forEach(session => {
          total = total + parseFloat(session.totalPlayedTime)
        })
        let watchedMinute = Math.floor(total / 60)
        return ScoreCalculationHelpers.calcedScore(this.showingEventId, DateTimeStringHelpers.toDate(key), watchedMinute)
      }).reduce((a, b) => a + b, 0)
    },
  },
}
</script>
